import React from 'react'
import './LandingPage.css';
import ocean from '../assets/background.jpg'
import boldlyLogo from '../assets/boldly-go-white-logo-1.png'
import instaLogo from '../assets/instagram-icon.png'
import linkedInLogo from '../assets/linked-in-icon.png'

const LandingPage = () => {

    const sendEmail = () => {
        window.location.href="mailto:hello@boldlygo.io";
    }

    const goToLink = (link: string) => {
        window.location.href=link;
    }

    return (
        <div className="App">
        <div className="App-content">
            <div className="App-title">
                <img
                    src={boldlyLogo}
                    alt="Boldly Go Logo"
                    className="App-logo"
                />
                <p className="App-copy">explore the universe of learning</p>
            </div>
            <div className="email-btn">
                <p className="email-link" onClick={() => sendEmail()}>hello@boldlygo.io</p>
            </div>
            <div className="App-footer">
                    <img
                        src={linkedInLogo}
                        alt="Linked logo link"
                        className="link-logo"
                        onClick={() => goToLink('https://www.linkedin.com/company/boldlygo')}
                    />
                <p className='legal-copy'>copyright 2020 - all rights reserved</p>
            </div>
        </div>
    </div>
    )

}

export default LandingPage
